<template>
  <crud-list
    :titulo-pesquisa="tituloPesquisa"
    :headers="headers"
    :items="items"
    :total-registros="totalRegistros"
    :paginacao.sync="pagination"
    @buscar-event="buscar"
    @novo-event="novo"
  >
    <template
      slot="items"
      slot-scope="{ item }"
    >
      <!-- <td colspan="4">{{ item }}</td> -->
      <td
        class="btnEditar cursorPointer"
        @click="editar(item.item)"
      >
        {{ item.item.nomeCondominio }}
        <v-icon small>
          mdi-pencil
        </v-icon>
      </td>
      <td>{{ item.item.blocos }}</td>
      <td>{{ item.item.totalApartamentos }}</td>
    </template>
  </crud-list>
</template>

<script>

  import CondominioService from '@/services/CondominiosService'

  export default {
    data: () => ({
      tituloPesquisa: 'Condomínios',
      totalRegistros: 0,
      pagination: {},
      headers: [
        {
          sortable: false,
          text: 'Nome',
          value: 'nomeCondominio'
        },
        {
          sortable: false,
          text: 'Blocos',
          value: 'blocos'
        },
        {
          sortable: false,
          text: 'Total Apartamentos',
          value: 'totalApartamentos'
        }
      ],
      items: [],
      loading: false,
      itemRemover: {}
    }),
    // created () {
    //   this.buscar()
    // },
    methods: {
      // deleteEventHandler (item) {
      //   this.itemRemover = item
      //   this.$root.$emit('mostra_dialogo_pergunta', {
      //     dialogoTitulo: 'Atenção',
      //     dialogoDescricao: 'Tens certeza que deseja remover este condomínio?',
      //     funcaoRetorno: this.remover
      //   })
      // },
      // remover () {
      //   const condominio = this.itemRemover
      //   try {
      //     CondominioService.delete(condominio)
      //       .then(response => {
      //         this.$root.$emit('mostra_dialogo_info', {
      //           dialogoTitulo: 'Sucesso',
      //           dialogoDescricao: 'Condomínio removido com sucesso'
      //         })
      //         this.buscar()
      //       })
      //   } catch (error) {
      //     this.$root.$emit('mostra_dialogo_erro', {
      //       dialogoDescricao: 'Ocorreu um erro ao remover um condomínio:\n ->' + error
      //     })
      //   }
      // },
      buscar () {
        this.loading = true
        CondominioService.findAll(this.pagination)
          .then((condominios) => {
            if (condominios && condominios.data && condominios.data.rows) {
              this.items = condominios.data.rows
            }
          })
          .catch(err => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: 'Erro ao buscar os condomínios cadastrados: \n' + err
            })
          }).finally(() => {
            this.loading = false
          })
      },
      novo () {
        this.$router.push({
          path: '/condominioForm'
        })
      },
      editar (item) {
        if (item && item.id) {
          this.$router.push({
            path: '/condominioForm/' + item.id
          })
        }
      }
    }
  }
</script>
<style>
#materialCardTeste .v-offset,
#materialCardTeste .v-offset .v-card--material__header {
  padding: 0px;
}
</style>
